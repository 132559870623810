<template>
  <v-container>
    <v-card
      v-if="flOrcamento"
      :height="height"
      class="d-flex align-center"
    >
      <v-row
        class="d-flex justify-center"
      >
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <strong
            style="font-size:30px"
            class="font-weight-medium primary--text"
          >Orçamento</strong>
          <v-text-field
            v-model="search"
            v-mask="'##/##/####-###-###'"
            autofocus
            label="Orçamento"
            single-line
            hide-details
            class="font-weight-medium text-center"
          >
            <template v-slot:append>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    medium
                    color="primary"
                    class="mt-0 pt-0"
                    @click="dialogCodgiBarras = true"
                  >
                    <v-icon v-on="on">
                      mdi-barcode
                    </v-icon>
                  </v-btn>
                </template>
                Pesquisar
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="flVendedor"
      :height="height"
      class="d-flex align-center"
    >
      <v-row
        class="d-flex justify-center"
      >
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <strong
            style="font-size:30px"
            class="font-weight-medium primary--text"
          >Conferente/Pesador</strong>
          <v-text-field
            v-show="flOrcamento"
            label="Regular"
          />
          <v-text-field
            v-model="conferentePesador"
            autofocus
            hide-details
            type="password"
            @keydown.enter="getConferentePesador"
          >
            <template v-slot:append>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    medium
                    color="primary"
                    class="mt-0 pt-0"
                    @click="dialogCodigoConferente = true"
                  >
                    <v-icon v-on="on">
                      mdi-barcode
                    </v-icon>
                  </v-btn>
                </template>
                Pesquisar
              </v-tooltip>
            </template>
          </v-text-field>
          <v-btn
            depressed
            color="error"
            class="mt-5"
            @click="onCancelar"
          >
            Cancelar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogCodgiBarras"
      fullscreen
    >
      <modalLeituraCodBarra @onPesquisaCodigoBarras="onPesquisaCodigoBarras" />
    </v-dialog>
    <v-dialog
      v-model="dialogCodigoConferente"
      fullscreen
    >
      <modalLeituraCodBarra
        @onPesquisaCodigoBarras="onPesquisaCodigoBarrasPesador"
        @onSair="onSairPesador"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import modalLeituraCodBarra from '../../componentes/componente-Leitura-CodigoBarra.vue'
  import orcamentoRep from '../../../components/services/orcamentoRepository'
  import pesadorRep from '../../../components/services/pesadorRepository'
  import orcamentoPesadorRep from '../../../components/services/orcamentopesadorRepository'
  export default {

    components: {
      modalLeituraCodBarra,
    },
    data () {
      return {
        search: '',
        conferentePesador: '',
        flOrcamento: true,
        flVendedor: false,
        dialogCodgiBarras: false,
        dialogCodigoConferente: false,
        cdOrcamento: null,
      }
    },
    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 600
          case 'sm': return 600
          case 'md': return 700
          case 'lg': return 700
          case 'xl': return 800
          default: return 600
        }
      },
    },
    watch: {
      // whenever question changes, this function will run
      search: function (novo, velho) {
        if (typeof (novo) !== 'undefined') {
          if (novo.length === 18) {
            this.getCodigoOrcamento()
          }
        }
      },
      // searchConferente: function (novo, velho) {
      //   if (typeof (novo) !== 'undefined') {
      //     this.getCodigoOrcamento()
      //   }
      // },
    },
    methods: {
      async getCodigoOrcamento () {
        this.$store.commit('setSplashScreen', true)
        try {
          await orcamentoRep.RetornaIdOrcamento(this.search).then(response => {
            this.$store.commit('setSplashScreen', false)
            this.cdOrcamento = response.data
            this.flOrcamento = false
            this.flVendedor = true
            this.searchConferentePesador = ''
            this.search = ''
          })
        } catch (error) {
          // debugger
          // console.log(error.response.data.detail)
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error.response.data.detail,
          })
          this.search = ''
        }
      },
      async getConferentePesador () {
        this.$store.commit('setSplashScreen', true)
        try {
          await pesadorRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            // debugger
            if (isNaN(this.conferentePesador) === false) {
              var flExiste = false
              for (let index = 0; index < response.data.dados.length; index++) {
                if (parseInt(this.conferentePesador) === response.data.dados[index].cdPesador) {
                  flExiste = true
                  this.salvarConferencia()
                  break
                }
              }
              // debugger
              if (flExiste === false) {
                this.$swal({
                  icon: 'warning',
                  text: 'Conferente/Pesador não existe!',
                })
              }
            } else {
              this.$swal({
                icon: 'warning',
                text: 'Valor inválido!',
              })
              this.searchConferente = ''
            }
          }).catch((error) => {
            // debugger
            // console.log(error.response.data.detail)
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error.response.data.detail,
            })
            this.conferentePesador = ''
          })
        } catch (error) {
          // debugger
          // console.log(error.response.data.detail)
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
          this.conferentePesador = ''
        }
      },
      async salvarConferencia () {
        this.$store.commit('setSplashScreen', true)
        try {
          var dados = {
            cdOrcamento: this.cdOrcamento,
            cdPesador: parseInt(this.conferentePesador),
          }
          await orcamentoPesadorRep.Salvar(dados).then(response => {
            this.flOrcamento = true
            this.flVendedor = false
            this.search = ''
            this.conferentePesador = ''
            this.$store.commit('setSplashScreen', false)
          })
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error.response.data.detail,
          })
          this.search = ''
          this.conferentePesador = ''
        }
      },
      onPesquisaCodigoBarras (codigoBarras) {
        // console.log(codigoBarras)
        this.dialogCodgiBarras = false
        this.search = codigoBarras
      },
      onPesquisaCodigoBarrasPesador (codigo) {
        // debugger
        this.dialogCodgiBarras = false
        this.conferentePesador = codigo
        this.getConferentePesador()
      },
      onSairPesador () {
        this.dialogCodigoConferente = false
      },
      onSair () {
        this.dialogCodigoConferente = false
      },
      onCancelar () {
        this.dialogCodigoConferente = false
        this.flOrcamento = true
        this.search = ''
        this.conferentePesador = ''
      },
    },
  }
</script>
<style>

.v-text-field input {
    font-size: 43px;
  }
</style>
