import Repository from './repository'

const resource = '/orcamentopesadora'

export default {

  async Salvar (data) {
    return Repository.post(`${resource}`, data)
  },
}
